import axios from "axios";
import { KTIcon } from "../../../helpers";
import { Tooltip } from "react-tooltip";
import { useIntl } from "react-intl";
import { useDemoInstancesRequest } from "../core/DemoInstancesProvider";
import { DemoInstance } from "../core/_models";

const ActionsManageUsers = (props: { 
  instance : DemoInstance;
  setInstance : (instance : DemoInstance) => void;
}) => {
  const { setRefetch } = useDemoInstancesRequest();
  const intl = useIntl();

  return (
    <div className="menu-item px-3">
      <button
        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
        onClick={() => props.setInstance(props.instance)}
        data-tooltip-id={`manageDemoInstanceUsers-${props.instance.id}`}
        aria-label={intl.formatMessage({ id: "DEMOINSTANCES.MANAGEUSERS" })}
      >
        <KTIcon iconName="user" className="fs-2 m-0 actionIcon" />
      </button>
      <Tooltip
        id={`manageDemoInstanceUsers-${props.instance.id}`}
        key={`manageDemoInstanceUsers-${props.instance.id}`}
        content={intl.formatMessage({ id: "DEMOINSTANCES.MANAGEUSERS" })}
        place="top"
        variant="dark"
        style={{ zIndex: 100 }}
      />
    </div>
  );
};

export default ActionsManageUsers;
