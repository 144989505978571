import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import DemoInstanceForm from "./DemoInstanceForm";

const DemoInstanceModal = (props : {
  setSwowModal : (value : boolean) => void;
}) => {
  const intl = useIntl();
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const prevActiveElement = useRef<HTMLElement | null>(null);

  useEffect(() => {
    document.body.classList.add("modal-open");
    prevActiveElement.current = document.activeElement as HTMLElement;
    if (closeButtonRef.current) {
      closeButtonRef.current.focus();
    }

    const trapFocus = (event: KeyboardEvent) => {
      if (event.key === "Tab") {
        const focusableElements =
          modalRef.current?.querySelectorAll<HTMLElement>(
            'a[href], button, textarea, input, select, [tabindex]:not([tabindex="-1"])'
          );
        if (focusableElements) {
          const firstElement = focusableElements[0];
          const lastElement = focusableElements[focusableElements.length - 1];

          if (event.shiftKey) {
            if (document.activeElement === firstElement) {
              event.preventDefault();
              lastElement.focus();
            }
          } else {
            if (document.activeElement === lastElement) {
              event.preventDefault();
              firstElement.focus();
            }
          }
        }
      }
    };

    document.addEventListener("keydown", trapFocus);

    return () => {
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", trapFocus);
      if (prevActiveElement.current) {
        prevActiveElement.current.focus();
      }
    };
  }, []);

  return (
    <div
      className="modal fade show d-block"
      id="demo_instance_modal"
      role="dialog"
      tabIndex={-1}
      aria-modal="true"
      aria-labelledby="demo_instance_modal_title"
      aria-describedby="demo_instance_modal_desc"
      ref={modalRef}
    >
      <div className="modal-dialog modal-xl modal-fullscreen-xl-down">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title" id="demo_instance_modal_title">
              {intl.formatMessage({ id: "DEMOINSTANCES.CREATENEW" })}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              onClick={() => props.setSwowModal(false)}
              aria-label={intl.formatMessage({ id: "BUTTON.CLOSE" })}
              ref={closeButtonRef}
            ></button>
          </div>
          <div className="modal-body" id="demo_instance_modal_desc">
            <DemoInstanceForm setCreateModal = {props.setSwowModal} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemoInstanceModal;
