import axios from "axios";
import { DemoInstance, DemoInstanceUser } from "./_models";

const getDemoInstances = async () => {

  const response = await axios.get("/instances/demo");

  return response.data.map((value: any) => {
    return {
      ...value
    }
  }) as DemoInstance[];

}

const getDemoInstanceUsers = async (instance_id: string) => {

  const response = await axios.get(`/instances/demo/${instance_id}/users`);

  return response.data as DemoInstanceUser[];

}

const addDemoInstanceUser = async (instance_id: string, payload: { email : string, first_name : string, last_name : string }) => {

  const response = await axios.post(`/instances/demo/${instance_id}/users`, payload);

  return response.data as DemoInstanceUser;

}

export { getDemoInstances, getDemoInstanceUsers, addDemoInstanceUser };
