import { Dispatch, SetStateAction } from "react";
import { examplePermissionsData, exampleRolesData } from "./__sampleData";

export type ID = string | undefined | null;

export type RoleUser = {
  id: ID;
  name: string;
  email: string;
};

export type Permission = {
  id: ID;
  name: string;
  description: string;
  state: string;
};

export type Role = {
  id: ID;
  roleName: string;
  description: string;
  users: Array<RoleUser>;
  permissions: Array<Permission>;
  actions: Array<string>;
};

export type IdForUpdate = {
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // STRING => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  id: ID;
  type: "ADD_NEW_ROLE" | "LIST_USERS" | "EDIT_ROLE" | null;
};

export type rolesAndPermissionsContextProps = {
  roles: Array<Role>;
  permissions: Array<Permission>;
  users: Array<RoleUser>;
  selected: Array<IdForUpdate>;
  roleIdForUpdate: IdForUpdate;
  setRoleIdForUpdate: Dispatch<SetStateAction<IdForUpdate>>;
};

export const initialRoleView: rolesAndPermissionsContextProps = {
  roles: exampleRolesData,
  permissions: examplePermissionsData,
  users: [],
  selected: [],
  roleIdForUpdate: { id: undefined, type: null },
  setRoleIdForUpdate: () => {}
};
