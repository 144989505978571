import React from 'react';
import { useIntl } from "react-intl";
import { DemoInstance } from "../core/_models";

interface DemoInstanceStatusCellProps {
  status: DemoInstance['status'];
}

const statusContentMap: Record<DemoInstance['status'], {
  badgeColor: string;
  text: string;
}> = {
  active: {
    badgeColor: "badge-light-success",
    text: "DEMOINSTANCES.STATUS.ACTIVE"
  },
  deleted: {
    badgeColor: "badge-light-danger",
    text: "DEMOINSTANCES.STATUS.DELETED"
  },
  deploying: {
    badgeColor: "badge-light-primary",
    text: "DEMOINSTANCES.STATUS.DEPLOYING"
  },
  error: {
    badgeColor: "badge-light-danger",
    text: "DEMOINSTANCES.STATUS.ERROR"
  }
};

export const DemoInstanceStatusCell: React.FC<DemoInstanceStatusCellProps> = ({ status }) => {

  const intl = useIntl();

  return (
    <div className={`badge ${statusContentMap[status]?.badgeColor} fw-bolder text-end px-4 py-2`} aria-label="Not active">
      { statusContentMap[status] ? intl.formatMessage({ id: statusContentMap[status].text }) : status}
    </div>
  );
};
