import { Formik, Form, FormikHelpers } from "formik";
import { FC, useState } from "react";

import { useIntl } from "react-intl";
import { TextField } from "../../../layout/components/form";
import { toast } from "react-toastify";
import { toastSettings } from "../../../layout/components/toast/Toast";
import { object, string } from "yup";
import { addDemoInstanceUser } from "../core/_requests";
import { DemoInstance } from "../core/_models";

const DemoInstanceUserForm: FC<{ instance: DemoInstance, refetch: () => void }> = ({ instance, refetch }) => {

  const intl = useIntl();

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const submit = (values: any, { resetForm } : FormikHelpers<any>) => {

    setIsSaving(true);

    addDemoInstanceUser(instance.id, values)
      .then((res) => {
        toast.success(
          intl.formatMessage({ id: "DEMOINSTANCES.USERS.CREATE.SUCCESS" }),
          toastSettings("success")
        );
        setIsSaving(false);
        resetForm();
        refetch();
      })
      .catch((err) => {
        console.error("Error uploading image", err);
        setIsSaving(false);
      });
  };

  return (

    <div className="d-flex flex-row-fluid flex-center">
      <Formik initialValues={{ email: '', first_name: '', last_name: '' }} validationSchema={object({
        email: string()
          .required("DEMOINSTANCES.USERS.CLIENTIDERROR")
          .email("DEMOINSTANCES.USERS.EMAILERROR"),
        first_name: string()
          .required("DEMOINSTANCES.USERS.FIRSTNAMEERROR"),
        last_name: string()
          .required("DEMOINSTANCES.USERS.LASTNAMEERROR")
      })} onSubmit={submit}>
        {({ values, touched, errors, setFieldValue, resetForm }) => {
          return (
            <Form className="mx-auto mw-800px w-100 pb-10" id="demo_instance_form">
              <div className="w-100">
                <div className="w-100 d-flex flex-column">

                  <div className="m-3">
                    <h2 id="email-label">
                      {intl.formatMessage({ id: "DEMOINSTANCES.USERS.EMAIL" })}
                    </h2>
                    <TextField
                      id="email"
                      label=""
                      description=""
                      // placeholder="example.com"
                      optional={false}
                      classes={errors.email && touched.email ? "border-danger" : ""}
                      isInvalid={false}
                      ariaLabelledBy="email-label"
                      ariaDescribedBy="email-description"
                    />
                    {errors.email && touched.email && (
                      <p className="text-danger">
                        {intl.formatMessage({ id: errors.email })}
                      </p>
                    )}
                  </div>

                  <div className="m-3">
                    <h2 id="instance-id-label">
                      {intl.formatMessage({ id: "DEMOINSTANCES.USERS.FIRSTNAME" })}
                    </h2>
                    <TextField
                      id="first_name"
                      label=""
                      description=""
                      // placeholder="example.com"
                      optional={false}
                      classes={errors.first_name && touched.first_name ? "border-danger" : ""}
                      isInvalid={false}
                      ariaLabelledBy="instance-id-label"
                      ariaDescribedBy="instance-id-description"
                    />
                    {errors.first_name && touched.first_name && (
                      <p className="text-danger">
                        {intl.formatMessage({ id: errors.first_name })}
                      </p>
                    )}
                  </div>
                  <div className="m-3">
                    <h2 id="instance-id-label">
                      {intl.formatMessage({ id: "DEMOINSTANCES.USERS.LASTNAME" })}
                    </h2>
                    <TextField
                      id="last_name"
                      label=""
                      description=""
                      // placeholder="example.com"
                      optional={false}
                      classes={errors.last_name && touched.last_name ? "border-danger" : ""}
                      isInvalid={false}
                      ariaLabelledBy="instance-id-label"
                      ariaDescribedBy="instance-id-description"
                    />
                    {errors.last_name && touched.last_name && (
                      <p className="text-danger">
                        {intl.formatMessage({ id: errors.last_name })}
                      </p>
                    )}
                  </div>

                  <div>
                    <button
                      type="submit"
                      className={isSaving ? "btn btn-primary me-2 disabled" : "btn btn-primary me-2"}
                    >
                      {!isSaving && <span className="indicator-label">{intl.formatMessage({ id: "BUTTON.ADD" })}</span>}
                      {isSaving && (
                        <span className="indicator-progress" style={{ display: "block" }}>
                          {intl.formatMessage({ id: "FORM.PLEASEWAIT" })}{" "}
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default DemoInstanceUserForm;
