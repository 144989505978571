/*eslint-disable  filenames/match-exported*/
import * as ack from "./ack.json";
import * as auth from "./auth.json";
import * as common from "./common.json";
import * as footer from "./footer.json";
import * as pagination from "./pagination.json";
import * as demo_instance from "./demo_instance.json";
import * as sidebar from "./sidebar.json";
import * as users from "./users.json";
import * as home from "./home.json";

import * as roles_and_permissions from "./roles_and_permissions.json";

const enMessages = {
  ...ack,
  ...auth,
  ...common,
  ...footer,
  ...pagination,
  ...demo_instance,
  ...sidebar,
  ...users,
  ...home,
  ...roles_and_permissions
};

export default enMessages;
