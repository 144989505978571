import { FC } from "react";
import { useIntl } from "react-intl";
import { formatDateForLocale } from "../../../helpers/localization/dateHelper";

interface DemoInstanceCreatedAtCellProps {
  created_at: string;
}

export const DemoInstanceCreatedAtCell: FC<DemoInstanceCreatedAtCellProps> = ({ created_at }) => {

  const intl = useIntl();
  const locale = intl.locale;

  const getFormattedDate = () => {
    try {
      return formatDateForLocale(
        new Date(created_at),
        locale,
        intl
      );
    } catch (error) {
      console.error("Invalid date", error);
      return "";
    }
  }

  return (
    <>
      <div
        className="fw-bolder text-start"
        aria-label="Created At"
      >
        {getFormattedDate()}
      </div>
    </>
  );
};
