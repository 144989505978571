import { toast, ToastOptions, ToastPosition, Zoom } from "react-toastify";

export function toastSettings(type: 'success' | 'error', customSettings: Partial<ToastOptions> = {}): ToastOptions {
    const baseSettings: ToastOptions = {
        position: "top-right" as ToastPosition,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
    };

    switch (type) {
        case 'success':
            return {
                ...baseSettings,
                theme: "colored",
                ...customSettings
                // Additional settings for success toasts if needed
            };
        case 'error':
            return {
                ...baseSettings,
                theme: "colored",
                transition: Zoom,
                ...customSettings
                // Additional settings for error toasts if needed
            };
        default:
            return {
                ...baseSettings,
                ...customSettings
            };
    }
}
