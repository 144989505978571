import { createContext, useContext, useEffect, useState } from "react";
import { DemoInstance, DemoInstancesContextProps, initialDemoInstancesRequest } from "./_models";
import { getDemoInstances } from "./_requests";

const DemoInstancesContext = createContext<DemoInstancesContextProps>(initialDemoInstancesRequest);

const DemoInstancesProvider = ({ children }: { children: any }) => {
  const [demoInstances, setDemoInstances] = useState<Array<DemoInstance>>([]);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [refetch, setRefetch] = useState<boolean>(false);

  useEffect(() => {
    setIsFetching(true);
    getDemoInstances()
      .then((instances) => {
        setDemoInstances(instances);
        setIsFetching(false);
        setRefetch(false);
      })
      .catch((error: any) => {
        console.error("Error : ", error);
        setIsFetching(false);
        setRefetch(false);
      });
  }, [refetch]);

  return (
    <DemoInstancesContext.Provider
      value={{
        demoInstances,
        isFetching,
        setRefetch
      }}
    >
      {children}
    </DemoInstancesContext.Provider>
  );
};

const useDemoInstancesRequest = () => useContext(DemoInstancesContext);

export { DemoInstancesProvider, useDemoInstancesRequest };
