export const getDateFormatForLocale = (locale: string): Intl.DateTimeFormatOptions => {
  switch (locale) {
    case 'fr':
      return { day: '2-digit', month: '2-digit', year: 'numeric' };
    case 'de':
      return { day: '2-digit', month: '2-digit', year: 'numeric' };
    case 'zh':
      return { year: 'numeric', month: 'numeric', day: 'numeric' };
    case 'ja':
      return { year: 'numeric', month: '2-digit', day: '2-digit' };
    case 'es':
      return { year: 'numeric', month: '2-digit', day: '2-digit' };
    case 'en':
    default:
      return { year: 'numeric', month: '2-digit', day: '2-digit' };
  }
};

export const formatDateForLocale = (date: Date, locale: string, intl: any): string => {
  const formatOptions = getDateFormatForLocale(locale);
  const formattedDate = intl.formatDate(date, formatOptions);

  if (locale === 'de') {
    const [day, month, year] = formattedDate.split('.');
    return `${day}.${month}.${year}`;
  } else if (locale === 'fr') {
    const [day, month, year] = formattedDate.split('/');
    return `${day}/${month}/${year}`;
  } else {
    return formattedDate;
  }
};
