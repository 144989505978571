import { Route, Routes, Outlet } from "react-router-dom";
import DemoInstanceConfigurator from "./DemoInstanceConfigurator";

const DemoInstancesPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path="/users" element={<DemoInstanceConfigurator />} />
      </Route>
      <Route index element={<DemoInstanceConfigurator />} />
    </Routes>
  );
};

export default DemoInstancesPage;
