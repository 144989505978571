import { Link } from "react-router-dom";
import {
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  User
} from "firebase/auth";
import { auth } from "../../../firebaseApp";
import Cookies from "js-cookie";
import { useIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../helpers";
import { useAuth } from "../core/Auth";
import { login } from "../core/_requests";
import { UserModel } from "../core/_models";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { StatusCodes } from "http-status-codes";
import axios from "axios";

export function Login() {
  const intl = useIntl();
  const { saveAuth, setCurrentUser } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [throwError, setThrowError] = useState<boolean | undefined>(undefined);
  const [warningMessage, setWarningMessage] = useState("");
  const [companyLogoURL, setCompanyLogoURL] = useState<any>(
    "https://storage.googleapis.com/insentric-staging-public/assets%2Fclient_logo.png"
  );
  const [isDelay, setIsDelay] = useState(false);

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((user) => authenticate({ user: user.user }))
      .catch((error) => {
        setThrowError(true);
        setWarningMessage(intl.formatMessage({ id: "AUTH.LOGIN.ERRGOOGLE" }));
        console.error(error);
      });
  };

  const authenticate = ({ user }: { user: User }) => {
    return user.getIdToken().then((idToken) => {
      const csrfToken = Cookies.get("csrfToken") as string;
      return login(idToken, csrfToken)
        .then((res) => {
          if (res.status === StatusCodes.OK) {
            saveAuth({ idToken, csrfToken });
            setCurrentUser(user as UserModel);
          } else {
            saveAuth(undefined);
            setThrowError(true);
            setWarningMessage(intl.formatMessage({ id: "AUTH.LOGIN.ERRINVALIDUSER" }));
          }
        })
        .catch((error) => {
          saveAuth(undefined);
          setThrowError(true);
          setWarningMessage(intl.formatMessage({ id: "AUTH.LOGIN.ERRINVALIDUSER" }));
          console.error(error);
        });
    });
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        { message: "AUTH.LOGIN.EMAILFORMAT" }
      )
      .required("AUTH.LOGIN.EMAILREQUIRED"),
    password: Yup.string().required("AUTH.LOGIN.PASSREQUIRED"),
  });

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setIsDelay(true);
      setTimeout(() => setIsDelay(false), 2000);

      signInWithEmailAndPassword(auth, values.email, values.password)
        .then((user) => authenticate({ user: user.user }))
        .catch((error) => {
          setThrowError(true);
          setWarningMessage(intl.formatMessage({ id: "AUTH.LOGIN.ERRCHECKEMAILORPASSWORD" }));
          console.error(error);
        });

      setLoading(false);
    },
  });

  useEffect(() => {
    axios.get("/assets/client_logo")
      .then((res) => setCompanyLogoURL(res.data))
      .catch((err) => console.log("err", err));
  }, []);

  return (
    <div className="form w-100">
      <div className="d-flex justify-content-center mb-11 d-none d-sm-none d-lg-flex">
        <Link to="/" className="pr-5 d-flex align-items-center" aria-label="Home">
          <img
            alt="Default Dark Logo"
            src={toAbsoluteUrl("/media/logos/default-dark.svg")}
            className="theme-dark-show h-45px"
          />
          <img
            alt="Default Light Logo"
            src={toAbsoluteUrl("/media/logos/default.svg")}
            className="theme-light-show h-45px"
          />
        </Link>
          <div className="pl-5 border-start border-gray-400 d-flex align-items-center">
            <span className="fs-1 fw-bold">Root Admin</span>
          </div>
      </div>

      <div className="row g-3 mb-9">
        <div className="col-md-12">
          <button
            onClick={signInWithGoogle}
            className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100"
            aria-label="Sign in with Google"
          >
            <img
              alt="Google Icon"
              src={toAbsoluteUrl("/media/svg/brand-logos/google-icon.svg")}
              className="h-25px me-3"
            />
            {intl.formatMessage({ id: "AUTH.LOGIN.GOOGLE" })}
          </button>
        </div>
      </div>

      {throwError && (
        <div
          className="alert alert-primary bg-light-danger border border-danger border-dashed d-flex align-items-center p-5"
          role="alert"
        >
          <i className="ki-duotone ki-shield-tick fs-2hx text-danger me-4">
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
          <div className="d-flex flex-column">
            <span className="text-danger">{warningMessage}</span>
          </div>
        </div>
      )}

    </div>
  );
}
