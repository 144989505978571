import { object, string, boolean } from "yup";

export const validateDemoInstanceForm = object({
  instance_id: string()
    .required("DEMOINSTANCES.CLIENTIDERROR")
    .lowercase("DEMOINSTANCES.LOWERCASE")
    .matches(/^[a-z]+$/, "DEMOINSTANCES.ONLYLETTERS"),
  name : string()
    .required("DEMOINSTANCES.NAMEERROR"),
  startup_boost : boolean()
});
