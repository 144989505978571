import { Formik, Form } from "formik";
import { FC, useState } from "react";
import axios from "axios";
import { initialValues } from "../core/_models";

import { useIntl } from "react-intl";
import { TextField } from "../../../layout/components/form";
import { validateDemoInstanceForm } from "../schema/validationSchema";
import { KTIcon } from "../../../helpers";
import CompanyLogoDropzone from "../components/CompanyLogoDropzone";
import { DataURIToBlob } from "../core/_helpers";
import { toast } from "react-toastify";
import { toastSettings } from "../../../layout/components/toast/Toast";
import { useDemoInstancesRequest } from "../core/DemoInstancesProvider";

const DemoInstanceForm: FC<{setCreateModal : (opt : boolean) => void}> = (props) => {

  const intl = useIntl();

  const [logo, setLogo] = useState<any>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { setRefetch } = useDemoInstancesRequest();

  const submit = (values: any) => {

    if (!logo) {
      toast.error(
        intl.formatMessage({ id: "DEMOINSTANCES.LOGO.ERROR" }),
        toastSettings("error")
      );
      return;
    }

    setIsSaving(true);
    const formData = new FormData();

    if (!logo.preview) {
      formData.append("file", DataURIToBlob(logo));
    } else {
      formData.append("file", logo);
    }

    formData.append("instance_id", values.instance_id);
    formData.append("name", values.name);
    formData.append("startup_boost", values.startup_boost?.toString() || "false");

    axios
      .post("/instances/demo", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then((res) => {
        toast.info(
          intl.formatMessage({ id: "DEMOINSTANCE.CREATE.SUCCESS" }),
          {
            autoClose : 15000,
            position : "bottom-center",
            theme : "colored"
          }
        );
      })
      .catch((err) => {
        toast.error(
          intl.formatMessage({ id: "DEMOINSTANCE.CREATE.ERROR" }),
          toastSettings("error", {
            position: "bottom-center"
          })
        );
        console.error("Error uploading image", err);
      }).finally(() => {
        setIsSaving(false);
        props.setCreateModal(false);
        setRefetch(true);
      })
  };

  return (

    <div className="d-flex flex-row-fluid flex-center">
      <Formik initialValues={initialValues} validationSchema={validateDemoInstanceForm} onSubmit={submit}>
        {({ values, touched, errors, setFieldValue }) => {
          return (
            <Form className="mx-auto mw-800px w-100 pb-10" id="demo_instance_form">
              <div className="w-100">
                <div className="w-100 d-flex flex-column">

                  <div className="m-3">
                    <h2 id="name-label">
                      {intl.formatMessage({ id: "DEMOINSTANCES.NAME" })}
                    </h2>
                    <div id="name-description" className="text-muted my-3">
                      {intl.formatMessage({ id: "DEMOINSTANCES.NAME.INSTRUCTIONS" })}
                    </div>
                    <TextField
                      id="name"
                      label=""
                      description=""
                      // placeholder="example.com"
                      optional={false}
                      classes={errors.name && touched.name ? "border-danger" : ""}
                      isInvalid={false}
                      ariaLabelledBy="name-label"
                      ariaDescribedBy="name-description"
                    />
                    {errors.name && touched.name && (
                      <p className="text-danger">
                        {intl.formatMessage({ id: errors.name })}
                      </p>
                    )}
                  </div>

                  <div className="m-3">
                    <h2 id="instance-id-label">
                      {intl.formatMessage({ id: "DEMOINSTANCES.INSTANCEID" })}
                    </h2>
                    <div id="instance-id-description" className="text-muted my-3">
                      {intl.formatMessage({ id: "DEMOINSTANCES.INSTANCEID.INSTRUCTIONS" })}
                    </div>
                    <TextField
                      id="instance_id"
                      label=""
                      description=""
                      // placeholder="example.com"
                      optional={false}
                      classes={errors.instance_id && touched.instance_id ? "border-danger" : ""}
                      isInvalid={false}
                      ariaLabelledBy="instance-id-label"
                      ariaDescribedBy="instance-id-description"
                    />
                    {errors.instance_id && touched.instance_id && (
                      <p className="text-danger">
                        {intl.formatMessage({ id: errors.instance_id })}
                      </p>
                    )}
                  </div>

                  <div className="m-3">
                    <div className="mb-10">
                      <div className="form-check form-switch form-check-custom form-check-solid justify-content-between align-items-center">
                        <h2>
                          {intl.formatMessage({ id: "DEMOINSTANCES.STARTUPBOOST" })}
                        </h2>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="startup_boost"
                          id="startup_boost"
                          checked={values.startup_boost}
                          onChange={(e: any) => {
                            setFieldValue("startup_boost", e.target.checked)
                          }}
                          aria-describedby="initial-conversion-description"
                        />
                      </div>
                      <div id="initial-conversion-description" className="text-muted my-3">
                        {intl.formatMessage({ id: "DEMOINSTANCES.STARTUPBOOST.INSTRUCTIONS" })}
                      </div>
                    </div>
                  </div>

                  <div>
                    <CompanyLogoDropzone image={logo} setImage={setLogo} />
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="btn btn-lg btn-primary me-3 mt-5"
                      data-demoinstance-modal-action="submit"
                    >
                      <span className="indicator-label">
                        Submit
                      </span>
                      <KTIcon iconName="arrow-right" className="fs-3 ms-2 me-0" />
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default DemoInstanceForm;
