import { DemoInstance } from "../core/_models";
import ActionsDelete from "./ActionsDelete";
import ActionsManageUsers from "./ActionsManageUsers";

const Actions = ({instance, setManageUsersModal} : {
  instance : DemoInstance,
  setManageUsersModal: any
}) => {

  return (
    <div className="d-flex flex-row justify-content-center">
      {instance.status === 'active' && <ActionsManageUsers instance = {instance} setInstance={setManageUsersModal} />}
      {instance.status !== 'deleted' && <ActionsDelete instance_id = {instance.id} />}
    </div>
  );
};

export default Actions;
