import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import DemoInstanceUsersTable from "./DemoInstanceUsersTable";
import { DemoInstance, DemoInstanceUser } from "../core/_models";
import DemoInstanceUserForm from "./DemoInstanceUserForm";
import { getDemoInstanceUsers } from "../core/_requests";

const DemoInstanceUsersModal = (props : {
  instance : DemoInstance;
  setSwowModal : (value : DemoInstance | null) => void;
}) => {
  const intl = useIntl();
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const prevActiveElement = useRef<HTMLElement | null>(null);

  const [demoInstanceUsers, setDemoInstanceUsers] = useState<DemoInstanceUser[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const refetch = () => {
    setIsLoading(true);

    getDemoInstanceUsers(props.instance.id).then((response) => {
      setDemoInstanceUsers(response);
    }).catch((error) => {
      console.error("Error loading demo instance users", error);
    }).finally(() => {
      setIsLoading(false);
    });
  }

  useEffect(refetch, [props.instance.id]);

  useEffect(() => {
    document.body.classList.add("modal-open");
    prevActiveElement.current = document.activeElement as HTMLElement;
    if (closeButtonRef.current) {
      closeButtonRef.current.focus();
    }

    const trapFocus = (event: KeyboardEvent) => {
      if (event.key === "Tab") {
        const focusableElements =
          modalRef.current?.querySelectorAll<HTMLElement>(
            'a[href], button, textarea, input, select, [tabindex]:not([tabindex="-1"])'
          );
        if (focusableElements) {
          const firstElement = focusableElements[0];
          const lastElement = focusableElements[focusableElements.length - 1];

          if (event.shiftKey) {
            if (document.activeElement === firstElement) {
              event.preventDefault();
              lastElement.focus();
            }
          } else {
            if (document.activeElement === lastElement) {
              event.preventDefault();
              firstElement.focus();
            }
          }
        }
      }
    };

    document.addEventListener("keydown", trapFocus);

    return () => {
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", trapFocus);
      if (prevActiveElement.current) {
        prevActiveElement.current.focus();
      }
    };
  }, []);

  return (
    <div
      className="modal fade show d-block"
      id="demo_instance_modal"
      role="dialog"
      tabIndex={-1}
      aria-modal="true"
      aria-labelledby="demo_instance_modal_title"
      aria-describedby="demo_instance_modal_desc"
      ref={modalRef}
    >
      <div className="modal-dialog modal-xl modal-fullscreen-xl-down">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title" id="demo_instance_modal_title">
              {intl.formatMessage({ id: "DEMOINSTANCES.USERS.TITLE" })} - {props.instance.name}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              onClick={() => props.setSwowModal(null)}
              aria-label={intl.formatMessage({ id: "BUTTON.CLOSE" })}
              ref={closeButtonRef}
            ></button>
          </div>
          <div className="modal-body" id="demo_instance_modal_desc">
          <div className="row px-8 pt-8">
          <div className="col-lg-12">
            <DemoInstanceUserForm instance={props.instance} refetch={refetch} />
          </div>
        </div>
            <DemoInstanceUsersTable isLoading={isLoading} demoInstanceUsers={demoInstanceUsers} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemoInstanceUsersModal;
