import { useState, useMemo } from "react";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import { DemoInstanceUser } from "../core/_models";

const DemoInstanceUsersTable = (props : {
  demoInstanceUsers : DemoInstanceUser[],
  isLoading : boolean
}) => {
  const intl = useIntl();

  const [sortField, setSortField] = useState<keyof DemoInstanceUser>("first_name");
  const [sortDirection, setSortDirection] = useState<"ascending" | "descending">("ascending");

  const sortData = (field: keyof DemoInstanceUser) => {
    const isAsc = sortField === field && sortDirection === "ascending";
    setSortDirection(isAsc ? "descending" : "ascending");
    setSortField(field);
  };

  const renderSortIcon = (field: string) => {
    if (sortField !== field) {
      return <FontAwesomeIcon icon={faSort} aria-hidden="true" />;
    }
    return sortDirection === "ascending" ? (
      <FontAwesomeIcon icon={faSortUp} aria-hidden="true" />
    ) : (
      <FontAwesomeIcon icon={faSortDown} aria-hidden="true" />
    );
  };

  const sortedDemoInstanceUsers = useMemo(() => {
    if (!props.demoInstanceUsers) return [];

    return [...props.demoInstanceUsers].sort((a, b) => {
      const aValue = a[sortField];
      const bValue = b[sortField];

      if (aValue < bValue) {
        return sortDirection === "ascending" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortDirection === "ascending" ? 1 : -1;
      }
      return 0;
    });
  }, [props.demoInstanceUsers, sortField, sortDirection]);

  return (
    <div className="col-12 p-3">
      {!props.isLoading ? (
        <div className="row">
          <div className="col-12 table-responsive">
            <table className="table align-middle gs-0 gy-4" role="table" aria-label="JS DemoInstances Table">
              <thead className="table-light">
                <tr className="fw-bold fs-7 text-muted bg-light py-4" role="row">
                  <th scope="col" className="text-center">
                    #
                  </th>
                  <th
                    className="sortable-column"
                    scope="col"
                    onClick={() => sortData("first_name")}
                    style={{ cursor: "pointer" }}
                    aria-sort={sortDirection}
                  >
                    {intl.formatMessage({
                      id: "DEMOINSTANCES.USERS.FIRSTNAME"
                    })}{" "}
                    {renderSortIcon("first_name")}
                  </th>
                  <th
                    className="sortable-column"
                    scope="col"
                    onClick={() => sortData("last_name")}
                    style={{ cursor: "pointer" }}
                    aria-sort={sortDirection}
                  >
                    {intl.formatMessage({
                      id: "DEMOINSTANCES.USERS.LASTNAME"
                    })}{" "}
                    {renderSortIcon("last_name")}
                  </th>
                  <th
                    className="sortable-column"
                    onClick={() => sortData("email")}
                    style={{ cursor: "pointer" }}
                    aria-sort={sortDirection}
                    scope="col"
                  >
                    {intl.formatMessage({
                      id: "DEMOINSTANCES.USERS.EMAIL"
                    })}{" "}
                    {renderSortIcon("email")}

                  </th>
                  <th scope="col" className="text-center" style={{ width: "1%" }}/>
                </tr>
              </thead>
              <tbody className="table-group-divider">
                {sortedDemoInstanceUsers.length > 0 ? (
                  sortedDemoInstanceUsers.map((user, index: any) => (
                    <tr key={index} role="row">
                      <th scope="row" className="text-center align-middle">
                        {index + 1}
                      </th>
                      <td className="align-middle">
                        {user.first_name}
                      </td>
                      <td className="align-middle">
                        {user.last_name}
                      </td>
                      <td className="align-middle">
                        {user.email}
                      </td>
                      <td className="align-middle">
                        {/* <DemoInstanceCreatedAtCell created_at={user.created_at} /> */}
                      </td>
                      <td className="d-flex align-items-center">
                        {/* <Actions instance_id={user.id} instance_status={user.status} setManageUsersModal={setManageUsers} /> */}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4}>
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ minHeight: "200px" }}
                        role="status"
                        aria-live="polite"
                      >
                        <h3>
                          {intl.formatMessage({
                            id: "DEMOINSTANCES.USERS.NOINSTANCES"
                          })}
                        </h3>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <span
          className="indicator-progress d-flex justify-content-center align-items-center pt-6 pb-6"
          role="status"
          aria-live="polite"
        >
          <span className="spinner-border spinner-border-lg align-middle ms-2" role="status" aria-hidden="true"></span>
          <span style={{ fontSize: 16, paddingLeft: 10 }}/>
        </span>
      )}

    </div>
  );
};

export default DemoInstanceUsersTable;
