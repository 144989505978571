/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useIntl } from "react-intl";
import { SidebarMenuItem } from "./SidebarMenuItem";

const SidebarMenuMain: React.FC = () => {
  const intl = useIntl();

  return (
    <>
      <SidebarMenuItem
        to="/app/home"
        title={intl.formatMessage({ id: "SIDEBAR.HOME" })}
        hasBullet={false}
        icon="home"
      />

      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            {intl.formatMessage({ id: "SIDEBAR.HEADER.USERMGMT" })}
          </span>
        </div>
      </div>

      <SidebarMenuItem
        to="/app/user-management/users"
        title={intl.formatMessage({ id: "SIDEBAR.ITEM.MANAGEUSERS" })}
        hasBullet={false}
        icon="profile-user"
      />

      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Demo Environments
          </span>
        </div>
      </div>

      <SidebarMenuItem
        to="app/demo-environments"
        title="Demo Environments"
        icon="data"
      />

    </>
  );
};

export { SidebarMenuMain };
