import { useIntl } from "react-intl";
import PageHeader from "../../layout/components/content/PageHeader";
import { KTIcon } from "../../helpers";
import DemoInstanceModal from "./edit-modal/DemoInstanceModal";
import DemoInstances from "./components/DemoInstances";
import "./style/style.scss";
import {
  DemoInstancesProvider,
  useDemoInstancesRequest
} from "./core/DemoInstancesProvider";
import { useState } from "react";
import DemoInstanceUsersModal from "./users-modal/DemoInstanceUsersModal";

const DemoInstancePage = () => {

  const [ showCreateModal, setShowCreateModal ] = useState<boolean>(false);

  const intl = useIntl();

  return (
    <>
      <div className="align-items-start d-flex flex-column mb-7">
        <PageHeader
          level2name="SIDEBAR.ITEM.DEMOINSTANCECONFIGURATOR"
          instructions="DEMOINSTANCES.INSTRUCTIONS"
        />
      </div>

      <div className={`card`}>
        <div className="card-header py-6">
          <div className="row">
            <div className="col-lg-12">
              <h2>
                {intl.formatMessage({
                  id: "DEMOINSTANCES.TITLE"
                })}
              </h2>
            </div>
          </div>
        </div>
        <div className="row px-8 pt-8">
          <div className="col-lg-12">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setShowCreateModal(true)}
              aria-label={intl.formatMessage({
                id: "DEMOINSTANCES.CREATE"
              })}
            >
              <KTIcon iconName="plus" className="fs-2" />
              {intl.formatMessage({
                id: "DEMOINSTANCES.CREATE"
              })}
            </button>
          </div>
        </div>
        <div className="row px-8 pb-8">
          <DemoInstances />
        </div>
      </div>
      {showCreateModal && <DemoInstanceModal setSwowModal={setShowCreateModal} />}
    </>
  );
};

const DemoInstanceConfigurator = () => {
  return (
    <DemoInstancesProvider>
      <DemoInstancePage />
    </DemoInstancesProvider>
  );
};

export default DemoInstanceConfigurator;
