import { Form, Formik } from "formik";
import PasswordFields from "./PasswordFields";
import PasswordRequirements from "./PasswordRequirements";
import { passwordValidationSchema } from "../schema/passwordValidationSchema";
import { useState } from "react";
import { EmailAuthCredential, EmailAuthProvider, reauthenticateWithCredential, updatePassword } from "firebase/auth";
import { toast } from "react-toastify";
import { toastSettings } from "../../../../layout/components/toast/Toast";
import { auth } from "../../../../firebaseApp";
import { useIntl } from "react-intl";

const initialValues = {
  currentPassword: "",
  password: "",
  confirmPassword: ""
};

const initialPasswoirdRequirements = {
  hasUpperCase: false,
  hasLowerCase: false,
  hasNumber: false,
  hasSpecialChar: false,
  isNotEmpty: false,
  isNotForbidden: true,
  isLongEnough: false,
  isMatching: false
};

const PasswordForm = () => {
  const intl = useIntl();

  const [passwordRequirements, setPasswordRequirements] = useState(initialPasswoirdRequirements);
  const [isSaving, setIsSaving] = useState(false);

  const onSubmit = (values: any) => {

    setIsSaving(true);
    if (auth.currentUser) {
      const currentUserEmail = auth.currentUser.email;
      const currentPassoword = values.currentPassword;
      let credential: EmailAuthCredential | null = null;

      if (currentUserEmail && currentUserEmail.length > 0 && currentPassoword && currentPassoword.length > 0) {
        credential = EmailAuthProvider.credential(currentUserEmail, values.currentPassword);
      }

      if (credential) {
        reauthenticateWithCredential(auth.currentUser, credential)
          .then((response) => {
            if (auth.currentUser && response) {
              updatePassword(auth.currentUser, values.password)
                .then((response) => {
                  toast.success(
                    intl.formatMessage({ id: "USER.MYPROFILE.SETTINGS.FORM.PASSWORD.UPDATE.SUCCESS" }),
                    toastSettings("success")
                  );
                })
                .catch((error) => {
                  toast.error(
                    intl.formatMessage({ id: "USER.MYPROFILE.SETTINGS.FORM.PASSWORD.UPDATE.ERROR" }),
                    toastSettings("error")
                  );
                });
            } else {
              toast.error(
                intl.formatMessage({ id: "USER.MYPROFILE.SETTINGS.FORM.PASSWORD.UPDATE.ERROR.USERVERIFICATION" }),
                toastSettings("error")
              );
            }
          })
          .catch((error) => {
            toast.error(
              intl.formatMessage({ id: "USER.MYPROFILE.SETTINGS.FORM.PASSWORD.UPDATE.ERROR.INCORRECT" }),
              toastSettings("error")
            );
          })
          .finally(() => {
            setIsSaving(false);
          });
      }
    }
  };

  return (
    <>
      <div className="pb-10">
        <p>{intl.formatMessage({ id: "USER.MYPROFILE.SETTINGS.FORM.PASSWORD.DESCRIPTION" })}</p>
      </div>
      <Formik initialValues={initialValues} validationSchema={passwordValidationSchema} onSubmit={onSubmit}>
        {({ values, touched, errors, setFieldValue }) => {
          return (
            <Form id="set-password">
              <PasswordFields
                setPasswordRequirements={setPasswordRequirements}
                setFieldValue={setFieldValue}
                values={values}
                errors={errors}
                touched={touched}
                isSaving={isSaving}
              />
            </Form>
          );
        }}
      </Formik>
      <PasswordRequirements requirements={passwordRequirements} />
    </>
  );
};
export default PasswordForm;
