import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate, Outlet } from 'react-router-dom'
import { MasterLayout } from '../../layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'

import { getCSSVariableValue } from '../../assets/ts/_utils'
import { WithChildren } from '../../helpers'
import { RolesAndPermissionsWrapper } from '../roles-permissions/RolesPermission'
import DependenciesAcknowledgement from '../misc-pages/dependencies-acknowledgement/DependenciesAcknowledgement'
import UserProfile from '../user-management/profile-page/UserProfile'
import DemoInstanceConfigurator from '../demo-instances/DemoInstanceConfigurator'
import DemoInstancesPage from '../demo-instances/DemoInstancesPage'

const AppContentRoutes = () => (
  <div>
    {/* Other layout content */}
    <Outlet />  {/* Nested routes will render here */}
  </div>
);
const PrivateRoutes = () => {

  const Home = lazy(() => import('../home/Home'));
  const UsersPage = lazy(() => import('../../pages/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Home after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/app/home' />} />
        {/* Pages */}
        <Route
          path='/app/'
          element={<AppContentRoutes />}
        >
          <Route path='home' element={<SuspensedView><Home /></SuspensedView>} />
          <Route path='user-management/*' element={<SuspensedView><UsersPage /></SuspensedView>} />
          <Route path='roles-and-permissions' element={<SuspensedView><RolesAndPermissionsWrapper /></SuspensedView>} />
          <Route path='demo-environments/*' element={<SuspensedView><DemoInstancesPage /></SuspensedView>} />
          <Route path='profile' element={<SuspensedView><UserProfile /></SuspensedView>} />

          <Route path='acknowledgements' element={<SuspensedView><DependenciesAcknowledgement /></SuspensedView>} />


        </Route>
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/app/home' />} />
      </Route>
    </Routes>
  )
}
const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
