import { useEffect, useState, useMemo } from "react";
import Actions from "./Actions";
import { useIntl } from "react-intl";
import { useDemoInstancesRequest } from "../core/DemoInstancesProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import { DemoInstance } from "../core/_models";
import { DemoInstanceStatusCell } from "./DemoInstanceStatusCell";
import { DemoInstanceCreatedAtCell } from "./DemoInstanceCreatedAtCell";
import DemoInstanceUsersModal from "../users-modal/DemoInstanceUsersModal";

const DemoInstances = () => {
  const intl = useIntl();

  const { isFetching, demoInstances } = useDemoInstancesRequest();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sortField, setSortField] = useState<string>("domain");
  const [sortDirection, setSortDirection] = useState<"ascending" | "descending">("ascending");
  const [manageUsersInstance, setManageUsersInstance] = useState<DemoInstance | null>(null);

  useEffect(() => {
    setIsLoading(isFetching);
  }, [isFetching]);

  const sortData = (field: string) => {
    const isAsc = sortField === field && sortDirection === "ascending";
    setSortDirection(isAsc ? "descending" : "ascending");
    setSortField(field);
  };

  const renderSortIcon = (field: string) => {
    if (sortField !== field) {
      return <FontAwesomeIcon icon={faSort} aria-hidden="true" />;
    }
    return sortDirection === "ascending" ? (
      <FontAwesomeIcon icon={faSortUp} aria-hidden="true" />
    ) : (
      <FontAwesomeIcon icon={faSortDown} aria-hidden="true" />
    );
  };

  const sortedDemoInstances = useMemo(() => {
    if (!demoInstances) return [];

    return [...demoInstances].sort((a, b) => {
      const aValue = a[sortField];
      const bValue = b[sortField];

      if (aValue < bValue) {
        return sortDirection === "ascending" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortDirection === "ascending" ? 1 : -1;
      }
      return 0;
    });
  }, [demoInstances, sortField, sortDirection]);

  return (
    <div className="col-12 p-3">
      {!isLoading ? (
        <div className="row">
          <div className="col-12 table-responsive">
            <table className="table align-middle gs-0 gy-4" role="table" aria-label="JS DemoInstances Table">
              <thead className="table-light">
                <tr className="fw-bold fs-7 text-muted bg-light py-4" role="row">
                  <th scope="col" className="text-center">
                    #
                  </th>
                  <th
                    className="sortable-column"
                    scope="col"
                    onClick={() => sortData("name")}
                    style={{ cursor: "pointer" }}
                    aria-sort={sortDirection}
                  >
                    {intl.formatMessage({
                      id: "DEMOINSTANCES.NAME"
                    })}{" "}
                    {renderSortIcon("name")}
                  </th>
                  <th
                    className="sortable-column"
                    scope="col"
                    onClick={() => sortData("instance_id")}
                    style={{ cursor: "pointer" }}
                    aria-sort={sortDirection}
                  >
                    {intl.formatMessage({
                      id: "DEMOINSTANCES.INSTANCEID"
                    })}{" "}
                    {renderSortIcon("instance_id")}
                  </th>
                  <th
                    className="sortable-column"
                    onClick={() => sortData("status")}
                    style={{ cursor: "pointer" }}
                    aria-sort={sortDirection}
                    scope="col"
                  >
                    {intl.formatMessage({
                      id: "DEMOINSTANCES.STATUS"
                    })}{" "}
                    {renderSortIcon("status")}

                  </th>
                  <th
                    className="sortable-column"
                    onClick={() => sortData("created_at")}
                    style={{ cursor: "pointer" }}
                    aria-sort={sortDirection}
                    scope="col"
                  >
                    {intl.formatMessage({
                      id: "DEMOINSTANCES.CREATEDAT"
                    })}{" "}
                    {renderSortIcon("created_at")}

                  </th>
                  <th scope="col" className="text-center" style={{ width: "1%" }}>
                    {intl.formatMessage({
                      id: "DEMOINSTANCES.ACTIONS"
                    })}
                  </th>
                </tr>
              </thead>
              <tbody className="table-group-divider">
                {sortedDemoInstances.length > 0 ? (
                  sortedDemoInstances.map((instance: DemoInstance, index: any) => (
                    <tr key={index} role="row">
                      <th scope="row" className="text-center align-middle">
                        {index + 1}
                      </th>
                      <td className="align-middle">
                        {instance.name}
                      </td>
                      <td className="align-middle">
                        {instance.instance_id}
                      </td>
                      <td className="align-middle">
                        <DemoInstanceStatusCell status={instance.status} />
                      </td>
                      <td className="align-middle">
                        <DemoInstanceCreatedAtCell created_at={instance.created_at} />
                      </td>
                      <td className="d-flex align-items-center">
                        <Actions instance={instance} setManageUsersModal={setManageUsersInstance} />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4}>
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ minHeight: "200px" }}
                        role="status"
                        aria-live="polite"
                      >
                        <h3>
                          {intl.formatMessage({
                            id: "DEMOINSTANCES.NOINSTANCES"
                          })}
                        </h3>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <span
          className="indicator-progress d-flex justify-content-center align-items-center pt-6 pb-6"
          role="status"
          aria-live="polite"
        >
          <span className="spinner-border spinner-border-lg align-middle ms-2" role="status" aria-hidden="true"></span>
          <span style={{ fontSize: 16, paddingLeft: 10 }}/>
        </span>
      )}
      {manageUsersInstance && <DemoInstanceUsersModal setSwowModal={setManageUsersInstance} instance={manageUsersInstance} />}

    </div>
  );
};

export default DemoInstances;
