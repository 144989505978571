import { Dispatch, SetStateAction } from "react";

export interface DemoInstance {
  id: string;
  name : string;
  instance_id: string;
  status: 'active' | 'deleted' | 'deploying' | 'error';
  created_at: string;
  updated_at: string;
  created_by: string;
  startup_boost?: boolean;
}

export interface DemoInstanceUser {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  created_at: string;
  updated_at: string;
}

export const initialValues = {
  instance_id: "",
  name: "",
  startup_boost: false
};

export type DemoInstancesContextProps = {
  demoInstances: Array<any>;
  isFetching: boolean;
  setRefetch: Dispatch<SetStateAction<boolean>>;
};

export const initialDemoInstancesRequest = {
  demoInstances: [],
  isFetching: true,
  setRefetch: () => {},
  setIsInitialAndConversion: () => {}
};
